@import "../../styles/colors.scss";

.tabs {
  &__title {
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin-bottom: 30px;
  }

  &__items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    border-bottom: 1px solid $color-gray-4;
  }
}
