.chat {
  &__messages,
  &__new-message {
    border: 1px solid #cdd1d4;
    padding: 20px;
  }

  &__messages {
    min-height: 30vh;
    max-height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__date {
    display: flex;
    gap: 10px;
    align-items: center;

    &-line {
      flex: 1;
      background-color: #cdd1d4;
      height: 2px;
    }

    &-text {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: #4c4c4c;
      vertical-align: middle;
    }
  }

  &__message {
    display: flex;

    &-box {
      background-color: #f9f9f9;
      padding: 10px 20px;
      max-width: 66%;
      border-radius: 4px;
      justify-self: right;
    }

    &--my-message {
      justify-content: flex-end;
      .chat__message-box {
        background-color: #e4f6ff;
      }
    }

    &-header {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: #4c4c4c;
      margin-bottom: 10px;
    }

    &-body {
      color: #535b63;
    }
  }

  &__new-message {
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      gap: 20px;
    }
  }
}
