@import "./../../styles/colors.scss";

.dropzone {
  width: 100%;
  font-family: HelveticaNeueCyr;

  &__header {
    &-text {
      color: $color-gray-3;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 15px 0;
    }
  }

  &__body {
    display: flex;
    border: 1px dashed $color-gray-4;
    padding: 20px;
    min-height: 108px;
    width: 100%;
    color: $color-gray-1;
    font-size: 16px;
    line-height: 26px;

    &:focus-visible {
      border-color: $color-blue-7;
    }

    &-content {
      display: flex;

      .dropzone__body-text {
        margin: -3px 0 0 10px;

        &--blue {
          color: $color-blue-7;
          cursor: pointer;
        }
      }
    }
  }

  &__icon {
    flex-shrink: 0;
  }

  &--drag-active {
    .dropzone__body {
      border-color: $color-blue-7;
    }
  }
  &--drag-rejected {
    .dropzone__header,
    .dropzone__header-text,
    .dropzone__body {
      color: $color-red-5;
    }

    .dropzone__body {
      border-color: $color-red-5;
    }
  }
}
