@import "../../kit/styles/colors.scss";

.answers-list {
  &__item {
    &-number {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      color: $color-black;
    }

    &-text {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: $color-black;

      margin-top: 20px;
    }
  }
}
