@import "../../../styles/colors.scss";

.tabs-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 20px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0px;
    background-color: $color-gray-10;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__title {
    font-family: ALS Ekibastuz;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: $color-gray-3;
  }

  &__description {
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: $color-gray-13;
  }

  &:hover {
    &::after {
      background-color: $color-gray-13;
      height: 2px;
    }
  }

  &--active {
    &::after {
      background-color: $color-blue-6;
      height: 4px;
    }

    .tabs-item__title {
      color: $color-blue-6;
    }

    .tabs-item__description {
      color: $color-black;
    }
  }
}
