@import "./../../styles/colors.scss", "./../../styles/mixins.scss",
  "./../../styles/vars.scss";

.radioButtons {
  position: relative;

  &__title {
    font-family: HelveticaNeueCyr;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  &--isError {
    border: 1px solid $color-red-5;
    border-radius: 4px;
    padding: 20px 10px;
  }

  &__isRequired {
    font-family: HelveticaNeueCyr;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-3;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &--horizontal {
      flex-direction: row;
    }
  }

  .radiobutton {
    display: flex;
    cursor: pointer;

    input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    &:focus-within {
      .radiobutton__checkmark {
        box-shadow: 0 0 0 5px $color-blue-4;
      }
    }

    &__checkmark {
      position: relative;
      height: 20px;
      width: 20px;
      margin-right: 30px;
      background-color: $color-white;
      border: 1px solid $color-gray-4;
      border-radius: 50%;

      &--active {
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: $color-blue-5;
          border-radius: 50%;
        }
      }
    }

    &:hover {
      .radiobutton__checkmark {
        box-shadow: 0 0 0 5px $color-blue-4;
        border-color: transparent;

        &::after {
          background-color: $color-blue-4;
        }
      }
    }

    &--active {
      .radiobutton__checkmark {
        box-shadow: 0 0 0 5px $color-blue-5;
        border-color: transparent;
      }
    }

    &--disabled {
      cursor: not-allowed;
      .radiobutton__checkmark {
        background: $color-gray-5;
        border: none;
      }

      &:hover {
        .radiobutton__checkmark {
          box-shadow: none;
          border-color: transparent;
        }
      }
    }

    &--disabled.radiobutton--active {
      .radiobutton__checkmark {
        background-color: transparent;
        box-shadow: 0 0 0 5px $color-gray-5;
        &::after {
          background-color: $color-gray-5;
        }
      }
    }

    &__text {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: $color-gray-1;
    }
  }

  &__error-button {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 17px;
    right: 0;

    border: 2px solid $color-red-6;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;
    background: none;

    &:after {
      @include absoluteCenter;
      content: "";
      display: block;
      height: 12px;
      width: 2px;
      border-top: 7px solid $color-red-6;
      border-bottom: 2px solid $color-red-6;
    }

    &:active {
      opacity: 0.6;
    }
  }
}
