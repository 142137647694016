.news-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    background: #f9f9f9;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    display: flex;
    flex-wrap: wrap;

    &-image {
      background: #cdd1d4;
      width: 230px;
      min-height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &-preview {
        height: 170px;
        object-fit: cover;
      }
    }

    &-body {
      padding: 30px 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-title {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: #535b63;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    &-buttons {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    &-date {
      &-title {
        font-family: HelveticaNeueCyr;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #c8c7cc;
      }

      &-value {
        font-family: HelveticaNeueCyr;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #969696;
      }
    }

    &-controls {
      display: flex;
      gap: 30px;
    }
  }
}
