@import "./../../styles/colors.scss", "./../../styles/vars.scss",
  "./../../styles/zindex.scss";

.ReactModal__Body--open {
  overflow: hidden;
}

.kit-modal {
  z-index: 1000;
  position: fixed;
  inset: 0;
  background-color: rgba($color-black, 0.24);
  display: flex;
  opacity: 0;
  transition: opacity 300ms ease;
  overflow-y: auto;

  &__content {
    margin: auto;
    background-color: $color-white;
    max-width: 880px;
    display: flex;
    flex-direction: column;
    overflow-anchor: none;

    &::after {
      content: "";
      position: sticky;
      display: none;
      width: 100%;
      height: 80px;
      bottom: 0;
      transition: opacity 300ms ease;
      opacity: 0;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        rgba($color-white, 0) 0%,
        $color-white 100%
      );
    }
  }

  &__header {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    position: sticky;
    top: 0;
    background-color: $color-white;
    transition: box-shadow 300ms ease;
    z-index: $zHeaderFixed;
  }

  &__title {
    font-family: "ALS Ekibastuz";
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    margin: 80px 0 30px 80px;
    transition: all 200ms ease;
  }

  &__close-button {
    margin-left: auto;
    padding: 0;
    background: none;
    border: 1px solid $color-gray-4;
    border-radius: 50%;
    display: flex;
    width: 42px;
    height: 42px;
    transition: background-color 300ms ease;
    flex-shrink: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 20px;

    .modal__close-icon {
      margin: auto;
    }

    &:focus-visible {
      box-shadow: $bs-control-focus-small;
    }

    &:hover {
      background-color: $color-gray-7;
      border-color: $color-gray-7;
    }

    &:active {
      background-color: $color-gray-8;
      border-color: $color-gray-8;
    }
  }

  &__body {
    padding: 0 100px 80px 100px;
  }

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }

  &--is-scrollable:not(&--is-scrolling) {
    .modal__content {
      &::after {
        opacity: 1;
        display: block;
      }
    }
  }

  &--is-scrolling {
    .modal__content {
      &::after {
        display: none;
      }
    }
    .modal__header {
      box-shadow: 0px 4px 64px rgba($color-black, 0.16);
    }

    .modal__title {
      margin: 8px 20px 0 0;
    }
  }

  @media (max-width: 1024px) {
    &__content {
      max-width: 680px;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
      margin: 66px 0 12px 80px;
    }

    &__body {
      padding-bottom: 64px;
    }
  }
  @media (max-width: 550px) {
    &__title {
      font-size: 17px;
      line-height: 22px;
      margin: 62px 0 2px 0;
    }

    &__body {
      padding: 0 20px 34px 20px;
    }
  }
}
