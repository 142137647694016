@import "../../kit/styles/colors.scss";

.header {
  &__top {
    background-color: $color-green-4;
    padding: 16px 0;
  }

  &__title {
    font-size: 15px;
    line-height: 20px;
    color: $color-white;
    font-family: ALS Ekibastuz;
    font-weight: bold;
  }

  &__bottom {
    background-color: $color-white;
    border-bottom: 1px solid $color-gray-10;

    &-body {
      display: flex;
      padding: 24px 0;
      justify-content: space-between;
      gap: 20px;
      align-items: center;

      &-central {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
      &-divider {
        width: 1px;
        height: 20px;
        background-color: $color-gray-10;
      }
    }
  }
}
