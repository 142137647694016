@import "../../kit/styles/colors.scss";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}

.container {
  max-width: 1200px;
  padding: 0 10px;
}

a {
  text-decoration: none;
}
