// common
$color-black: #000000;
$color-white: #ffffff;

$color-main: #333333;
$color-support: #76767a;
$color-indigo: #5b75cb;

// red
$color-red-1: #d51a1a;
$color-red-2: #d0021b;
$color-red-3: #bb0217;
$color-red-4: #a80215;
$color-red-5: #dd191d;
$color-red-6: #ed4960;
$color-red-7: #db4056;
$color-red-8: #fff2f3;
$color-red-9: #e73a3d;

// gray
$color-gray-1: #535b63;
$color-gray-2: #576472;
$color-gray-3: #4c4c4c;
$color-gray-4: #cdd1d4;
$color-gray-5: #dbe2e6;
$color-gray-6: #565656;
$color-gray-7: #f5f5f5;
$color-gray-8: #e0e0e0;
$color-gray-9: #969696;
$color-gray-10: #c8c7cc;
$color-gray-11: #f3f3f3;
$color-gray-12: #f7f9f9;
$color-gray-13: #576472;
$color-gray-14: #a5aeb3;
$color-gray-15: #f9f9f9;
$color-gray-16: #fff8f8;
$color-gray-17: #e2e2e2;
$color-gray-18: #c7d0d6;

// blue
$color-blue-1: #0063b0;
$color-blue-2: #023e83;
$color-blue-3: #002957;
$color-blue-4: #bfe5ff;
$color-blue-5: #6ca9d2;
$color-blue-6: #1e3685;
$color-blue-7: #0173c1;

$color-green-1: #86d564;
$color-green-2: #e9f9d7;
$color-green-3: #699f38;
$color-green-4: #55bb31;
$color-green-5: #f5ffeb;
$color-green-6: #84bf41;

$color-yelow-1: #fffadf;
$color-yelow-2: #ffa004;

$color-orange-1: #f4821f;

$color-transparent-1: #ffffffff;
