@import "../../styles/colors.scss";

.checkboxes {
  &__title {
    font-family: HelveticaNeueCyr;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  &__isRequired {
    font-family: HelveticaNeueCyr;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-3;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &--horizontal {
      flex-direction: row;
    }
  }
}
