@import "../../kit/styles/colors.scss";

.link {
  text-decoration: none;
  font-family: ALS Ekibastuz;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: $color-blue-7;

  &:hover {
    color: darken($color-blue-7, 10%);
  }

  &--active {
    color: darken($color-blue-7, 20%);
  }
}
