.balls-page {
  .h2 {
    margin: 30px 0;
    font-weight: 700;
  }

  &__text-block {
    padding: 30px;
    background: #f9f9f9;
    display: flex;

    .news-preview {
      flex: 1;
      background: inherit;
      padding: 0;
    }

    .button-container {
      margin-left: 40px;
    }

    &--text {
      font-weight: normal;
      color: #535b63;
    }
  }
}
