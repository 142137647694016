@import "../../kit/styles/colors.scss";

.vote-item-dates {
  display: flex;
  gap: 20px;

  &__divider {
    width: 1px;
    height: 48px;
    background: $color-gray-5;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-title {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;

      color: #576472;
    }
    &-value {
      font-family: ALS Ekibastuz;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #4c4c4c;
    }
  }
}
