.cascade-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__container {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
  }

  * > .cascade-checkbox-item {
    margin-top: 20px;
  }
}
