@font-face {
  font-family: "ALS Ekibastuz";
  src: url("Ekibastuz-Regular.eot");
  src: local("Ekibastuz Regular"), local("Ekibastuz-Regular"),
    url("Ekibastuz-Regular.eot?#iefix") format("embedded-opentype"),
    url("Ekibastuz-Regular.woff2") format("woff2"),
    url("Ekibastuz-Regular.woff") format("woff"),
    url("Ekibastuz-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ALS Ekibastuz";
  src: url("Ekibastuz-ExtraBlack.eot");
  src: local("Ekibastuz ExtraBlack"), local("Ekibastuz-ExtraBlack"),
    url("Ekibastuz-ExtraBlack.eot?#iefix") format("embedded-opentype"),
    url("Ekibastuz-ExtraBlack.woff2") format("woff2"),
    url("Ekibastuz-ExtraBlack.woff") format("woff"),
    url("Ekibastuz-ExtraBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ALS Ekibastuz";
  src: url("Ekibastuz-Black.eot");
  src: local("Ekibastuz Black"), local("Ekibastuz-Black"),
    url("Ekibastuz-Black.eot?#iefix") format("embedded-opentype"),
    url("Ekibastuz-Black.woff2") format("woff2"),
    url("Ekibastuz-Black.woff") format("woff"),
    url("Ekibastuz-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ALS Ekibastuz";
  src: url("Ekibastuz-Bold.eot");
  src: local("Ekibastuz Bold"), local("Ekibastuz-Bold"),
    url("Ekibastuz-Bold.eot?#iefix") format("embedded-opentype"),
    url("Ekibastuz-Bold.woff2") format("woff2"),
    url("Ekibastuz-Bold.woff") format("woff"),
    url("Ekibastuz-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "ALS Ekibastuz";
  src: url("Ekibastuz-Heavy.eot");
  src: local("Ekibastuz Heavy"), local("Ekibastuz-Heavy"),
    url("Ekibastuz-Heavy.eot?#iefix") format("embedded-opentype"),
    url("Ekibastuz-Heavy.woff2") format("woff2"),
    url("Ekibastuz-Heavy.woff") format("woff"),
    url("Ekibastuz-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ALS Ekibastuz";
  src: url("Ekibastuz-Light.eot");
  src: local("Ekibastuz Light"), local("Ekibastuz-Light"),
    url("Ekibastuz-Light.eot?#iefix") format("embedded-opentype"),
    url("Ekibastuz-Light.woff2") format("woff2"),
    url("Ekibastuz-Light.woff") format("woff"),
    url("Ekibastuz-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
