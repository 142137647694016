@import "../../kit/styles/colors.scss";

.vote-item-mode-switcher {
  display: flex;
  border: 1px solid $color-gray-4;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $color-white;
  overflow: hidden;
  width: fit-content;
  flex-wrap: wrap;

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    cursor: pointer;
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;

    &:not(:last-child) {
      border-right: 1px solid $color-gray-4;
    }

    &--active {
      background-color: $color-blue-5;
      color: $color-white;
    }

    &:hover {
      background-color: lighten($color-blue-5, 10%);
      color: $color-white;
    }
  }
}
