@import "../../styles/colors.scss";

.icon-error {
  width: 24px;
  height: 24px;
  background-color: $color-white;
  border-radius: 50%;
  border: 2px solid $color-red-5;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: darken($color-red-5, 15%);
    &::before,
    &::after {
      background-color: darken($color-red-5, 15%);
    }
  }

  &::before {
    content: "";
    background-color: $color-red-5;
    width: 2px;
    height: 8px;
    position: absolute;
    top: 3px;
    left: 9px;
  }

  &::after {
    content: "";
    background-color: $color-red-5;
    width: 2px;
    height: 2px;
    position: absolute;
    top: 14px;
    left: 9px;
  }
}
