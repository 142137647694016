@import "../../../kit/styles/colors.scss";

.step-was-now {
  &__container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__title {
    font-family: ALS Ekibastuz;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
  }

  &__was,
  &__now {
    background-color: #E4F6FF;
    padding: 30px 20px 40px 20px;
    max-width: 565px;
    flex-grow: 1;
    
  }
  &__now {    
    .file-selector__container {
      margin-top: 59px;
    }
  }

  .divider {
    margin: 9px 0 20px 0;
  }
}

.file-selector {
  &__title {
    margin-bottom: 20px;
  }
  &__container{
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    width: 152px;
    height: 165px;
    border: 1px solid transparent;    

    &--selected {      
      border: 1px solid #A6FC45;
      box-shadow: 0px 2px 4px rgba(132, 191, 65, 0.08);
      .dashed-button {
        color: $color-green-6;
      }
    }
  }
  &__files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .files-preview__item {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
  }  
  &__file-footer {
    height: 45px;
    width: 100%;
    background-color: $color-white;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 10px 0 0 15px;
  }
  &__no-photo {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background: #cdd1d4;
    width: 150px;
    min-height: 119px;
    display: flex;
    justify-content: center;    
    align-items: center;
    overflow: hidden;
  }
}
