@import "../../kit/styles/colors.scss";

.vote-item-detail {
  &__footer {
    &-status {
      font-family: ALS Ekibastuz;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;

      color: $color-gray-2;

      &--active {
        color: $color-gray-3;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 30px;

      &-right {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
      }
    }
  }
}
