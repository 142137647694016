.voting-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;

    background-color: #ececec;
    border-radius: 4px;
    padding: 30px;
    display: flex;
    gap: 20px;
    flex-direction: column;

    &--is-publish {
      background-color: #f9f9f9;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      gap: 30px;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-title {
          font-family: HelveticaNeueCyr;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: #535b63;
          cursor: pointer;

          &:hover {
            color: #0173c1;
          }
        }

        &-dates {
          font-family: HelveticaNeueCyr;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: #969696;
        }
      }

      &-buttons {
        &-container {
          display: flex;
          gap: 30px;
          align-items: center;
        }

        &-edit,
        &-remove {
          font-family: HelveticaNeueCyr;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          cursor: pointer;
          text-decoration: underline;
          text-decoration-style: dashed;
          text-decoration-thickness: 1px;
          text-underline-offset: 4px;
        }
        &-edit {
          color: #0173c1;
          &:hover {
            color: darken(#0173c1, 10%);
            text-decoration-thickness: 2px;
          }
        }
        &-remove {
          color: #e73a3d;
          &:hover {
            color: darken(#e73a3d, 10%);
            text-decoration-thickness: 2px;
          }
        }

        &-divider {
          width: 1px;
          height: 20px;
          background: #88c5e7;
        }
      }
    }

    &-bottom {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
    }
  }
}
