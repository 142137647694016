.checkbox-hide-block {
  &__content {
    margin-top: 20px;
    margin-left: -20px;
    padding: 40px 20px;
    background: #e4f6ff;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      background: #e4f6ff;
      transform: rotate(45deg);
      top: -8px;
      left: 22px;
    }
  }
}
