.throbber-container {
  height: 35vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.throbber-svg svg {
  padding: 2px;
  -webkit-animation: throbberRotateX 1.5s infinite cubic-bezier(0.8, 0, 0, 0.8);
  animation: throbberRotateX 1.5s infinite cubic-bezier(0.8, 0, 0, 0.8);
  will-change: transform;
  box-sizing: content-box;
}

.throbber-svg .throbber-path {
  -webkit-transform-origin: 46px 50px;
  transform-origin: 46px 50px;
  -webkit-animation: throbberRotateZ 1.5s infinite linear,
    throbberColor 6s infinite cubic-bezier(0.8, 0, 0, 0.8);
  animation: throbberRotateZ 1.5s infinite linear,
    throbberColor 6s infinite cubic-bezier(0.8, 0, 0, 0.8);
  will-change: transform;
}

.throbber-svg .throbber-big {
  width: 104px;
  height: 104px;
  margin: auto;
}

.throbber-big svg {
  width: 104px;
  height: 104px;
  -webkit-transform-origin: 0 52px;
  transform-origin: 0 54px;
}

@-webkit-keyframes throbberRotateX {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}
@keyframes throbberRotateX {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}

@-webkit-keyframes throbberRotateZ {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  25% {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }
  50% {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  75% {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
}

@keyframes throbberRotateZ {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  25% {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }
  50% {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  75% {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
}

@-webkit-keyframes throbberColor {
  0%,
  100% {
    fill: #00aadd;
  }
  25% {
    fill: #00cb3c;
  }
  50% {
    fill: #d71956;
  }
  75% {
    fill: #ff9700;
  }
}
@keyframes throbberColor {
  0%,
  100% {
    fill: #00aadd;
  }
  25% {
    fill: #00cb3c;
  }
  50% {
    fill: #d71956;
  }
  75% {
    fill: #ff9700;
  }
}

.line-1 {
  font-weight: normal;
  font-size: 18px;
  color: #000000;
  margin-bottom: 14px;
  text-align: center;
}
.line-2 {
  font-size: 15px;
  margin-bottom: 40px;
  color: #535b63;
}
