@import "../../styles/colors.scss";

.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__buttons-group {
    border-collapse: collapse;

    & > button {
      border-radius: 0;
      border-right: 0px;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:disabled {
        height: 42px;
        width: 70px;

        &:hover {
          border: 1px solid $color-gray-4;
          border-right: 0px !important;
        }
      }

      &:last-child {
        border-right: 1px solid $color-gray-4;
        border-radius: 0 4px 4px 0;
      }

      &:hover {
        border-left: 1px solid $color-gray-4;

        &:last-child {
          border: 0px;
          border-left: 1px solid $color-gray-4;
        }

        &:first-child {
          border: 0px;
        }
      }

      &:focus {
        z-index: 10;
        border-radius: 4px;
      }
    }
  }

  &__button {
    height: 42px;
    width: 70px;

    &--disabled:disabled {
      color: $color-gray-6;
      cursor: default;

      &:hover {
        border: 1px solid $color-gray-4;
        color: $color-gray-6;
      }
    }
  }

  &__size,
  &__count,
  &__buttons-group {
    display: flex;
  }

  &__count {
    & > button {
      &:first-child,
      &:last-child {
        width: 70px;
        height: 42px;
        padding: 0px;

        svg {
          width: 40%;
          fill: $color-blue-1;
        }
      }

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }
  }

  .page__support {
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    color: $color-gray-6;
  }
}
