@import "../../kit/styles/colors.scss";

.vote-item-questions-list {
  &__item {
    position: relative;

    &-title {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: $color-black;
    }

    &-text {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: $color-black;
    }
  }
}
