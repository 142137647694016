@import "../../styles/colors.scss";

.info-table {
  display: flex;
  flex-direction: column;

  &__header {
    font-family: ALS Ekibastuz;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: $color-black;
    margin-bottom: 30px;
  }

  .table {
    &__cell:first-child {
      width: 33%;
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: $color-gray-9;
    }
    &__cell:not(:first-child) {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: $color-gray-3;
    }
  }

  &__divider {
    width: 100%;
    height: 4px;
    background-color: $color-gray-10;
  }
}
