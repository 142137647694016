.declaration-info {
  border-collapse: separate;
  border-spacing: 0 10px;

  &__row {
    &-label {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #576472;
      width: 170px;
    }
    &-value {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #576472;
    }
  }
}
