.leaflet-container {
  height: 100%;
  width: 100%;
  min-width: 200px;
  min-height: 400px;
  z-index: 1;
}

.leaflet-marker-icon {
  width: 64px !important;
  height: 87px !important;
  left: -26px;
  top: -67px;
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='87' viewBox='0 0 64 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M55.0375 23.5187C55.0375 36.5078 41.2812 56.3563 31.5187 71C23.5312 57.2437 8 36.5078 8 23.5187C8 10.5297 18.5297 0 31.5187 0C44.5078 0 55.0375 10.5297 55.0375 23.5187Z' fill='%236CA9D2'/%3E%3Cpath d='M53.0375 23.5187C53.0375 29.4526 49.8436 37.2631 45.1893 45.6702C41.1211 53.0185 36.1082 60.5335 31.6165 67.2529C30.1542 64.8441 28.5328 62.2803 26.8635 59.6407C26.6315 59.2738 26.3985 58.9054 26.1649 58.5358C23.5849 54.4532 20.9162 50.1993 18.4998 45.9781C16.0814 41.7534 13.9419 37.6061 12.4107 33.7456C10.8708 29.8634 10 26.4028 10 23.5187C10 11.6343 19.6343 2 31.5187 2C43.4032 2 53.0375 11.6343 53.0375 23.5187Z' stroke='white' stroke-width='4'/%3E%3C/g%3E%3Ccircle cx='31.9626' cy='23.075' r='12.425' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='63.0375' height='87' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='8'/%3E%3CfeGaussianBlur stdDeviation='4'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}
