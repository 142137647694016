.button-back {
  display: flex;
  gap: 4px;
  cursor: pointer;
  color: #0173c1;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  &__icon {
    width: 12px;
    height: 12px;
    border-left: 2px solid #0173c1;
    border-bottom: 2px solid #0173c1;
    transform: rotate(45deg);
  }
}
