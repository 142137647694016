// Utils
$base: 0; // базовый zIndex
$above: 1; // $above + $base - над базой
$below: -1; // $below + $base - под базой

/** elements **/
$zTooltip: $above + $base;
$zDropdown: $above + $base;
$zHeaderFixed: $above + $base;

// пример Page Layout

// header и footer на 1 выше базового уровня
// $zLayoutHeader: $above + $base;
// $zLayoutFooter: $above + $base;

// modal над header
// $zLayoutModal: $above + $zLayoutHeader
// popup над modal
// $zLayoutPopUpAd: $above + $zLayoutModal;
