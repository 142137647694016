@import "../../kit/styles/colors.scss";

.analytics-table {
  border-collapse: collapse;

  &__header {
    border-bottom: 4px solid $color-gray-10;

    &-cell {
      text-align: center;
      padding: 10px;
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #535b63;

      &--left {
        text-align: left;
      }

      &--bordered {
        border-bottom: 2px solid #c8c7cc;
      }
    }
  }

  &__body {
    &-row {
      border-bottom: 1px solid #c8c7cc;

      &:hover {
        background-color: #c8c7cc50;
      }
    }

    &-cell {
      padding: 10px;
    }

    &-cell:not(:first-child) {
      text-align: center;
    }
  }
}
