.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  & > * {
    flex: 1;
  }
}
