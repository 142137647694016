@import "../../kit/styles/colors.scss";

.declaration-list {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;

  &__row {
    background: $color-gray-16;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .table__cell:first-child {
      padding-left: 0;
    }

    .table__cell:last-child {
      padding-right: 0;
    }

    &--not-checked {
      background: $color-green-5;
    }

    &--on-control {
      background-color: $color-gray-17;
    }
  }

  &__cell {
    text-align: left;
    vertical-align: top;
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    padding: 20px;
    color: $color-gray-1;
  }

  &__header {
    .declaration-list__cell {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      border-bottom: 4px solid $color-gray-10;
    }
    .declaration-list__row {
      background-color: transparent;
    }
  }

  &__item {
    &-days-left {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #ffa004;

      &--over {
        color: $color-red-9;
      }
    }
  }
}
