@import "../../kit/styles/colors.scss";

.steps-container {
  &__item {
    position: relative;

    &-header {
      display: flex;
      align-items: center;

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        margin-right: 28px;
        background-color: $color-gray-18;

        &-content {
          margin-top: 0px;
          font-family: "HelveticaNeueCyr";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: $color-white;
        }
      }

      &-title {
        font-family: "ALS Ekibastuz";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: $color-gray-10;
      }
    }

    &-body {
      padding-bottom: 10px;
      padding-left: 44px;
      position: relative;
      min-height: 36px;
      padding-top: 30px;

      font-family: HelveticaNeueCyr;
      font-size: 15px;
      line-height: 20px;
      color: $color-gray-1;

      &::before {
        content: "";
        width: 4px;
        height: calc(100% - 16px);
        background-color: $color-gray-8;
        margin-top: 8px;
        position: absolute;
        top: 0;
        left: 8px;
      }
    }

    &:last-child {
      .steps-container__item-body::before {
        display: none;
      }
    }

    &--complete {
      .steps-container__item-header-number {
        background-color: $color-green-6;
        .steps-container__item-header-number-content {
          color: $color-white;
        }
      }
      .steps-container__item-body::before {
        background: $color-green-6;
      }

      .steps-container__item-header-title {
        color: $color-black;
      }

      &:first-child {
        .steps-container__item-header-number {
          border: 5px solid $color-green-6;
          background-color: $color-white;
        }
      }
    }

    &:first-child {
      .steps-container__item-header-number {
        border-color: $color-green-6;
      }

      .steps-container__item-header-title {
        color: $color-black;
      }
    }
  }
}
