@import "../../kit/styles/vars.scss";
@import "../../kit/styles/colors.scss";

.dashed-button {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 3px;
  font-family: $font-helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: $color-blue-7;

  &--blue {
    color: $color-blue-7;
  }
  &--red {
    color: $color-red-9;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
