@import "assets/scss/utils/vars.scss";

.warning .ck-editor {
  outline: 1px solid $error-color;
  margin-bottom: 0;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.ck-content {
  strong {
    font-weight: bold;
  }
  i {
    font-style: revert;
  }
  ol,
  ul {
    list-style: revert;
    padding-left: revert;
  }
  h2,
  h3,
  h4 {
    font-style: revert;
    font-size: revert;
    font-weight: revert;
  }
}
