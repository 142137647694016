@import "../../styles/colors.scss";

.checkbox {
  display: flex;

  align-items: center;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__mark {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin-right: 30px;
    content: "";
    border: 1px solid $color-gray-4;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &::after {
      content: "";
      display: none;
      height: 6px;
      width: 8px;
      position: absolute;
      top: 6px;
      left: 6px;
      border-left: 2px solid;
      border-bottom: 3px solid;
      border-color: $color-white;
      transform: rotate(-45deg);
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: $color-gray-1;
  }

  &:hover,
  &:focus {
    .checkbox__mark {
      box-shadow: 0 0 0 5px $color-blue-4;
      border: none;
    }
  }

  &:focus > &input:disabled {
    .checkbox__mark {
      box-shadow: none;
      border: none;
    }
  }

  input:checked ~ .checkbox__mark {
    background-color: $color-blue-5;
    border: none;

    &::after {
      display: block;
    }
  }

  input:disabled ~ .checkbox__mark {
    background-color: $color-gray-5;
    border: none;
    cursor: not-allowed;

    &::after {
      border-color: $color-gray-9;
    }

    &:hover {
      box-shadow: 0 0 0 0 transparent;
    }
  }

  input:disabled ~ .checkbox__text {
    cursor: not-allowed;
  }
}
