@import "../../../kit/styles/colors.scss";

.main-page-module-item {
  background-color: $color-white;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid $color-gray-11;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    box-shadow: 5px 5px 10px -5px rgba($color-black, 0.2);
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-top {
      display: flex;
      gap: 20px;
      align-items: center;

      &-title {
        font-family: HelveticaNeueCyr;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
      }
    }

    &-bottom {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: $color-gray-1;
    }
  }
}
