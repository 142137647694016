@import "./../../styles/colors.scss", "./../../styles/mixins.scss",
  "./../../styles/vars.scss";

.files-list {
  font-family: HelveticaNeueCyr;
  width: 100%;

  &__error-top {
    color: $color-red-2;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 15px 10px 15px 0;
    border-top: 1px solid $color-gray-4;
    gap: 20px;
  }

  &__file-status {
    display: flex;
    width: 54px;
    height: 54px;
    position: relative;
    flex-shrink: 0;
    background-color: $color-white;

    &--with-shadow {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: -1;
        border: 1px solid $color-gray-4;
        border-radius: 4px;
      }
    }

    &-inner {
      width: 100%;
      height: 100%;
      border: 1px solid $color-gray-4;
      border-radius: 4px;
      overflow: hidden;

      .files-list__file-preview {
        width: auto;
        height: 100%;
      }

      .files-list__status-icon {
        @include absoluteCenter;
      }

      .files-list__file-progress {
        position: absolute;
        height: 8px;
        right: 7px;
        left: 7px;
        bottom: 7px;
        border: 1px solid $color-gray-4;
        border-radius: 4px;
        overflow: hidden;

        &-bar {
          background-color: $color-green-3;
          height: 6px;
          border-radius: 3px;
          transition: transform 600ms ease;
        }
      }
    }
  }

  &__item-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    word-break: break-all;
    font-size: 12px;
    line-height: 16px;

    & > p {
      margin: 0;
    }

    .files-list__item-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: $color-gray-14;

      &--error {
        color: $color-gray-2;
      }

      &--success {
        color: $color-black;
      }
    }

    .files-list__item-error {
      color: $color-red-2;
    }

    .files-list__item-file-size {
      color: $color-gray-3;
    }
  }

  &__button-remove {
    display: flex;
    width: 24px;
    height: 24px;
    margin-left: auto;
    background: none;
    border: 1px solid $color-gray-4;
    border-radius: 50%;
    flex-shrink: 0;
    cursor: pointer;
    transition: transform 200ms ease-in;

    & > svg {
      margin: auto;
    }

    &:active {
      opacity: 0.6;
    }

    &:hover {
      transform: scale(1.08);
    }

    &:focus-visible {
      box-shadow: $bs-control-focus-small;
    }
  }
}
