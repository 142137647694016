@import "./../../styles/colors.scss", "./../../styles/vars.scss",
  "./../../styles/mixins.scss";

.datepicker {
  width: 100%;
  position: relative;

  &__label {
    @include absoluteCenter($vertical: true);
    left: 19px;
    right: 0;
    pointer-events: none;
    overflow: hidden;
    transition: all 300ms ease;
    color: $color-gray-1;
    white-space: nowrap;

    &--top {
      top: 8px;
      font-size: 13px;
      line-height: 13px;
      color: $color-gray-9;
    }
  }

  &__required-label {
    position: absolute;
    font-size: 13px;
    top: 100%;
    padding-top: 4px;
    padding-left: 20px;
  }

  &--error {
    .datepicker__input {
      border: 1px solid $color-red-5;
      padding-right: 44px;
    }

    .datepicker__error-button {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      border: 2px solid $color-red-6;
      border-radius: 50%;
      margin-right: 20px;
      cursor: pointer;
      background: none;

      &:after {
        @include absoluteCenter;
        content: "";
        display: block;
        height: 12px;
        width: 2px;
        border-top: 7px solid $color-red-6;
        border-bottom: 2px solid $color-red-6;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }
}

.react-datepicker {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  width: 320px;
  color: $color-blue-1;
  background-color: $color-white;
  border: 1px solid $color-gray-4;
  box-shadow: 0 0 5px $color-gray-4;

  .react-datepicker__triangle {
    display: none;
  }

  &__input-container {
    & > input {
      width: 100%;
      font-size: 17px;
      line-height: 18px;
      padding: 19px 18px;
      border: 1px solid $color-gray-4;

      &:focus {
        box-shadow: $bs-control-focus;
      }
    }
  }

  &__month-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    .react-datepicker__date-title {
      font-weight: 700;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    background: inherit;

    .react-datepicker__day-names {
      display: flex;
      padding: 14px 10px;
      color: $color-blue-6;

      .react-datepicker__day-name {
        width: 2.2rem;
        margin: 2px;
      }
    }
  }

  &__month {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__week {
    display: flex;
  }

  &__day {
    font-size: 16px;
    padding: 6px;
    cursor: pointer;
    width: 2.2em;
    text-align: center;
    margin: 2px;
    color: inherit;

    &--outside-month {
      color: $color-gray-3;
    }

    &:hover:not(&--selected),
    &--keyboard-selected,
    &--in-range,
    &--in-selecting-range {
      background-color: $color-blue-4;
    }

    &--selected {
      background-color: $color-blue-1;
      color: $color-white;
    }

    &--disabled {
      color: $color-gray-3;
      cursor: not-allowed;

      &:hover {
        background-color: inherit;
      }
    }

    &--range-start,
    &--range-end {
      box-shadow: 0 0 0 3px $color-blue-5;
      z-index: 1;
    }
  }

  &__today-button {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    background: inherit;
    border: none;
    flex-grow: 1;
  }

  &__input-time-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: auto;
    flex-grow: 1;
  }
}
