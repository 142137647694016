.shop-analytics {
  .table__container {
    .analytic {
      .table__header {
        .table__row {
          .table__cell:nth-child(1) {
            width: 80px;
          }
          .table__cell:nth-child(2) {
            width: 960px;
          }
        }
      }
    }
  }
  .button-container {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  &__count {
    font-weight: 700;
    color: #969696;

    span {
      color: #565656;
    }
  }
}
