@import "./../../styles/colors.scss", "./../../styles/mixins.scss",
  "./../../styles/vars.scss";

.textarea {
  position: relative;
  display: flex;
  width: 100%;

  &__textarea {
    width: 100%;
    font-size: 17px;
    line-height: 18px;
    padding: 19px 18px;
    border: 1px solid $color-gray-4;
    resize: none;
    max-height: 50vh;

    &:focus {
      box-shadow: $bs-control-focus;
    }
  }

  &__placeholder {
    position: absolute;
    top: 16px;
    left: 19px;
    right: 0;
    pointer-events: none;
    overflow: hidden;
    transition: all 300ms ease;
    color: $color-gray-1;
    white-space: nowrap;
  }

  .textarea__error-button {
    display: none;
  }

  &--error {
    .textarea__textarea {
      border: 1px solid $color-red-5;
      padding-right: 44px;
    }

    .textarea__error-button {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 17px;
      right: 0;

      border: 2px solid $color-red-6;
      border-radius: 50%;
      margin-right: 20px;
      cursor: pointer;
      background: none;

      &:after {
        @include absoluteCenter;
        content: "";
        display: block;
        height: 12px;
        width: 2px;
        border-top: 7px solid $color-red-6;
        border-bottom: 2px solid $color-red-6;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }

  .textarea__textarea:not(:placeholder-shown) + .textarea__placeholder,
  .textarea__textarea:focus + .textarea__placeholder {
    top: 4px;
    font-size: 13px;
    line-height: 13px;
    color: $color-gray-9;
  }

  &__required-label {
    position: absolute;
    font-size: 13px;
    top: 100%;
    padding-top: 4px;
    padding-left: 20px;
  }
}
