@import "../../kit/styles/colors.scss";

.categories-table {
  border-collapse: collapse;

  &__header {
    border-bottom: 4px solid $color-gray-10;

    &-row {
    }

    &-cell {
      padding: 20px 0;
      padding-right: 20px;
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
    }
  }

  &__body {
    &-row {
      &:hover {
        background-color: darken($color-white, 2%);
      }

      &--bordered {
        border-left: 1px solid $color-gray-5;
      }
    }

    &-cell {
      padding: 10px 0;
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #4c4c4c;
      padding-right: 20px;

      &--clickable {
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }

      &--level-0 {
        font-family: "ALS Ekibastuz";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        color: #1e3685;
      }

      &--level-1 {
        font-family: "ALS Ekibastuz";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
      }

      &--level-2 {
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
      }

      &--inline {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-end;
      }

      &--deactivated {
        font-family: "HelveticaNeueCyr";
        color: red;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
}
