@import "../../kit/styles/colors.scss";

.login-form {
  background-color: $color-white;
  width: 480px;
  border-radius: 4px;
  padding: 50px;

  &__header {
    font-size: 24px;
    line-height: 27px;
  }

  &__sub-header {
    font-size: 14px;
    line-height: 20px;
  }
}
