.category-tags {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__new-tag {
    display: flex;
    gap: 30px;

    .text-field {
      flex: 1;
    }
  }

  &__list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    &-item {
      background: #e4f6ff;
      border: 1px solid #cdd1d4;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 14px 20px;
      display: flex;
      gap: 10px;
      align-items: center;

      &-delete {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
