@import "../../styles/colors.scss";

.warning-card {
  padding: 20px;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  &--yellow {
    background-color: $color-yelow-1;
  }

  &--red {
    background-color: $color-red-8;

    .warning-card__icon {
      border-color: darken($color-red-8, 50%);

      &::before,
      &::after {
        background-color: darken($color-red-8, 50%);
      }
    }
  }

  &--green {
    background-color: $color-green-2;
    .warning-card__icon {
      border-color: darken($color-green-2, 50%);

      &::before,
      &::after {
        background-color: darken($color-green-2, 50%);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
  }

  &__header {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
  }

  &__text {
    font-size: 15px;
    line-height: 20px;
    color: $color-gray-1;
  }

  &__icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid;
    border-color: darken($color-yelow-1, 50%);
    position: relative;
    box-sizing: border-box;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      background-color: darken($color-yelow-1, 50%);
      left: calc(50% - 1px);
    }

    &:before {
      top: 4px;
      height: 8px;
    }

    &:after {
      top: 14px;
      height: 2px;
    }
  }

  &__close-icon {
    content: "";
    width: 12px;
    height: 12px;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 17px;
      background-color: $color-gray-3;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::after,
      &::before {
        background-color: $color-gray-2;
      }
    }
  }
}
