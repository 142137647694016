@import "../../styles/colors.scss";

.table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  &__container {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-10;
    }

    &:hover {
      background-color: darken($color-white, 2%);
    }

    .table__cell:first-child {
      padding-left: 0;
    }

    .table__cell:last-child {
      padding-right: 0;
    }
  }

  &__cell {
    text-align: left;
    vertical-align: top;
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    padding: 20px;
    color: $color-gray-1;
  }

  &__header {
    .table__cell {
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      border-bottom: 4px solid $color-gray-10;
    }
  }
}
