.template-page {
  &__list {
    list-style-type: none;
    padding-left: 0;
    width: 800px;

    li {
      margin-bottom: 20px;
      display: flex;
      border-bottom: 1px solid #cdd1d4;
    }

    &--templ {
      width: 300px;
    }

    &--desc {
      width: 400px;
      margin-bottom: 10px;
    }
  }
}

.main-templates {
  &__list {
    &--item {
      cursor: pointer;
      margin-bottom: 40px;
    }
  }
}
