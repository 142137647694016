@import "../../kit/styles/colors.scss";

.days-left-count {
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: $color-gray-9;

  &__value {
    color: $color-black;

    &--negative {
      color: $color-red-9;
    }
  }
}
