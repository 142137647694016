@import "../../kit/styles/colors.scss";

.list-mode-switcher {
  display: flex;

  &__item {
    padding: 14px 56px;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: $color-gray-6;
    cursor: pointer;
    border: 1px solid $color-gray-4;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &--active {
      background: $color-blue-5;
      color: $color-white;
    }
  }
}
