@import "assets/scss/utils/vars.scss";

.input__textarea {
  border: 1px solid $border-base;
  min-height: 102px;
  padding: 0.8rem 1.25rem;
  font-size: $mainFontSize;
  font-family: $mainFont;

  &::placeholder {
    color: $label-color;
  }
}
