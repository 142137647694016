// структура и нейминг классов зависит от переданых пропсов
// className="react-select"
// classNamePrefix="react-select"

@import "../../styles/colors.scss";

.select {
  position: relative;

  &__placeholder {
    position: absolute;
    left: 18px;
    top: 5px;
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: #969696;
  }

  .react-select {
    .react-select__control {
      background-color: #fff;
      border-radius: 4px;
      border-color: #cdd1d4;
      &--is-focused {
        border-color: $color-blue-4;
        box-shadow: 0px 0px 0px 5px $color-blue-4;
      }

      .react-select__value-container {
        font-family: HelveticaNeueCyr;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 19px;
        color: #000000;
        padding: 14px;
      }

      .react-select__indicators {
      }
    }
    .react-select__menu {
      .react-select__menu-list {
        .react-select__option {
          padding: 20px 16px;
          font-family: HelveticaNeueCyr;
          font-size: 17px;
          line-height: 19px;
          color: #576472;

          &--is-selected {
            background-color: transparent;
            padding-left: 40px;
            position: relative;

            &::before {
              position: absolute;
              content: "";
              left: 18px;
              top: 24px;
              width: 10px;
              height: 4px;
              border-left: 3px solid;
              border-bottom: 3px solid;
              border-color: inherit;
              transform: rotate(-45deg);
            }
          }

          &--is-focused,
          &:hover {
            background-color: #6ca9d2;
            color: #ffffff;
          }
        }
      }
    }
  }

  &--error {
    .react-select {
      .react-select__control {
        border-color: $color-red-6;
      }
    }
  }
}
