@import "../../styles/colors.scss";

.accordion {
  margin-top: 28px;

  &__header {
    max-width: 100%;
    cursor: pointer;

    &-row {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }

    &-text {
      font-family: Ekibastuz;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: $color-black;
    }

    &-icon {
      margin-left: 15px;

      &--reverse {
        transform: scale(1, -1);
      }
    }

    &-sub-text {
      margin-top: 10px;
      font-size: 15px;
      line-height: 20px;
      color: $color-gray-9;
    }
  }

  &__body {
    margin-top: 30px;

    &--hide {
      display: none;
    }
  }
}
