.category-select {
  &__placeholder {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #576472;
    margin-bottom: 8px;
  }

  &__close {
    display: flex;
    gap: 10px;

    &-title {
      font-family: "ALS Ekibastuz";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #4c4c4c;
    }

    &-divider {
      color: #b5ddf3;
    }
  }

  &__open {
  }

  &__variants {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-item {
      font-family: "ALS Ekibastuz";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #4c4c4c;
      cursor: pointer;

      display: flex;
      gap: 12px;
      align-items: center;

      &:hover {
        color: #84bf41;
      }

      &--selected {
        color: #1e3685;
      }
    }
  }
}
