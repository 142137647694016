// Font -----------------------------------------------------------------------
$mainFont: "Helvetica Neue";

// Size -------------------------------------------------------------
$mainFontSize: 17px;
$mainFontWeight: 300;
$mainLineHeight: 1.4;

$desktopWidth: 1280px;
$smDesktopWidth: 1024px;
$tableWidth: 768px;
$smTableWidth: 640px;
$phoneWidth: 480px;
$smPhoneWidth: 320px;

// font color -----------------------------------------------------------------

$mainFontColor: #000000;
$ligthGreyFont: #535b63;
$ligtherGreyFont: #afafaf;
$darkGreyFont: #565656;
$darkerGreyFont: #333333;
$placeholderFont: #666666;
$inputGreyFont: #222222;

// Main color -----------------------------------------------------------------

$default-color: #ffffff;
$breadcrumbs-color: #f6f6f6;
$link-color: #0173c1;
$error-color: #db4056;
$warning-color: #d43b54;
$label-color: #b3bcc0;
$primary-background: #2862ac;
$primary-blue-color: #0063b0;
$secondary-blue-color: #6ca9d2;
$light-blue-color: #f0f8ff;
$ultra-light-blue-color: #b7e1ff25;
$light-grey-color: #f5f5f5;
$ultra-light-grey-color: #fafafa;
$placeholder-background-color: #dee5e9;
$option-color: #74a9d5;
$green-color: #219653;
$success-color: #155724;
$success-background: #e1f8e6;

// Neutral Color --------------------------------------------------------------
$neutral-primary: #303133;
$neutral-regular: #606266;
$neutral-secondary: #909399;
$neutral-placeholder: #c0c4cc;
$neutral-placeholder-secondary: #b3bcc0;

// Border Color ---------------------------------------------------------------
$border-base: #cdcfd1;
$border-light: #e0e0e0;
$border-lighter: #c8c7cc;
$border-blue: #0173c1;
$border-opaque: rgba(255, 255, 255, 0.5);
$border-grey: #e4e4e4;
$border-secondary: #dbdcde;
$border-grey-secondary: #828282;
$border-hover: #bfe5ff;
$border-file: #cdd1d4;

$daypicker-disabled-background: #f7f9f8;
$daypicker-disabled-day: #b5bebd;
$daypicker-active-day: #4a90e2;
$daypicker-date: #8b9898;

$box-shadow-color: #dee6ed;

// Transition settings ---------------------------------------------------------
$scale-coefficient: 1.05;

// =============================================================================

$step-color: #f4821f;
$step-color-current: #c4c4c4;

// mixins ----------------------------------------------------------------------
@mixin stretched-absolute-block {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
