.categories-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    background: #ececec;
    border-radius: 4px;
    padding: 30px;
    &--active {
      background: #f9f9f9;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #535b63;
      }

      &-buttons {
        display: flex;
        gap: 30px;
      }
    }
  }
}
