/**
* make position 'absolute' and center element inside a container
* only vertical if argument $vertical == true
* @mixin
*/
@mixin absoluteCenter($vertical: false) {
  position: absolute;
  top: 50%;

  @if $vertical == false {
    left: 50%;
    transform: translate(-50%, -50%);
  } @else {
    transform: translateY(-50%);
  }
}
