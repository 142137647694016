@import "./../../styles/colors.scss", "./../../styles/mixins.scss",
  "./../../styles/zindex.scss";

.tooltip {
  padding-top: 20px;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  color: $color-white;
  z-index: $zTooltip;

  &__inner {
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    background-color: $color-red-7;
    padding: 20px;

    .tooltip__content {
      display: flex;
      flex-direction: column;

      .tooltip__title {
        flex-grow: 1;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        padding-bottom: 15px;
      }

      .tootip__text {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .tooltip__close-button {
      width: 20px;
      height: 20px;
      background: $color-white;
      border-radius: 4px;
      flex-shrink: 0;
      border: none;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $color-gray-7;
      }

      &:focus {
        opacity: 0.8;
      }

      &:focus-visible {
        box-shadow: 0px 0px 0px 3px $color-blue-4;
      }

      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 5px;
        transform: translateY(-50%);
        width: 10px;
        height: 1px;
        background-color: $color-gray-3;
      }

      &:after {
        transform: rotate(45deg);
      }

      &:before {
        transform: rotate(-45deg);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 20px;
      height: 20px;
      background-color: $color-red-7;
      transform: rotate(45deg) translateY(-50%);
      transform-origin: center;
    }
  }
}
