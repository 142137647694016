@import "../../../styles/colors.scss";

.show-hide-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $color-white;
  border: 1px solid $color-gray-5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    top: 5px;
    border-right: 2px solid $color-gray-6;
    border-bottom: 2px solid $color-gray-6;
    transform: rotate(45deg);
    position: absolute;
  }

  &--reverse {
    transform: rotate(180deg);
  }
}
