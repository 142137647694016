@import "../../kit/styles/colors.scss";

.files-preview {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &__item {
    width: 150px;
    height: 120px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid $color-gray-4;
    background-color: $color-white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-icon {
      position: absolute;
      background-color: #fff;
      width: 24px;
      height: 24px;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      border: 1px solid #cdd1d4;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-img {
      width: 150px;
      height: 120px;
      object-fit: cover;
    }

    &-remove {
      margin-top: 10px;
      font-family: HelveticaNeueCyr;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: $color-red-5;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-offset: 3px;
      }

      &-icon {
        background-color: #fff;
        content: "x";
        width: 24px;
        height: 24px;
        border: 1px solid #cdd1d4;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.popup {
  &__img {
    max-width: 100%;
    max-height: 80vh;
  }
}
