@import "../../styles/colors.scss";

.divider {
  background-color: $color-blue-5;
  height: 1px;
  width: 100%;

  &--grey {
    background-color: $color-gray-4;
  }
}
