@import "../../styles/colors.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__background {
    background-color: rgba($color-black, 50%);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__window {
    position: relative;
    background-color: $color-white;
    padding: 80px 100px;
    border-radius: 4px;
    max-width: 100vw;
    max-height: 100vh;

    &-title {
      font-family: ALS Ekibastuz;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
      color: $color-black;
      margin-bottom: 40px;
    }
  }

  &__close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    border: 1px solid $color-gray-4;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-11;
    }
  }
}
