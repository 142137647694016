.shop-settings-component {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  margin-bottom: 40px;

  &__controllers {
    display: flex;
    gap: 40px;
  }
}
