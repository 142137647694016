.list-item-card {
  background: #ececec;
  border-radius: 4px;
  padding: 30px;

  &--active {
    background: #f9f9f9;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &-title {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #535b63;

      &--clickable {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &-buttons {
      display: flex;
      gap: 30px;
    }
  }

  &__description {
    display: flex;
    gap: 8px;

    &-title {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #c8c7cc;
    }

    &-value {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #969696;
    }
  }

  &__controls {
    display: flex;
    gap: 30px;
    align-items: center;
  }
}
