@import "../../../kit/styles/colors.scss";

.vote-question {
  background: $color-gray-15;
  border-radius: 4px;

  &__container {
    padding: 30px;
  }

  &__title {
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: $color-black;
  }

  &__row {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    &-item {
      flex: 1;
      flex-basis: 210px;

      &:first-child {
        flex-grow: 8;
        flex-basis: 320px;
      }
      &:not(:first-child) {
        flex-grow: 3;
      }
    }
  }
}
