@import "../../kit/styles/colors.scss";

.list-counter {
  display: flex;
  gap: 20px;

  &__item {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    &-title {
      color: $color-gray-9;
    }
    &-value {
      color: $color-black;
    }
  }
}
