.header-profile {
  display: flex;
  gap: 20px;
  align-items: center;

  &__exit-icon {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
