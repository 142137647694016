@import "./../../styles/colors.scss";

.button {
  margin: 0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 4px;
  text-align: center;
  position: relative;
  transition: background-color 0.3s;
  cursor: pointer;

  &--small {
    font-size: 14px;
    line-height: 14px;
    padding: 14px 20px;
  }

  &--medium {
    font-size: 17px;
    line-height: 17px;
    padding: 27px 48px;
  }

  &--large {
    font-size: 24px;
    line-height: 24px;
    padding: 23px 48px;
  }

  &--primary {
    background-color: $color-blue-1;
    color: $color-white;

    &:hover {
      background-color: $color-blue-2;
    }

    &:active {
      background-color: $color-blue-3;
    }
  }

  &--secondary {
    background-color: $color-white;
    border: 1px solid $color-gray-4;
    color: $color-gray-6;

    &:hover {
      background-color: $color-gray-7;
      border-color: $color-gray-7;
    }
    &:active {
      background-color: $color-gray-8;
      border-color: $color-gray-8;
    }
  }

  &--danger {
    background-color: $color-red-2;
    color: $color-white;

    &:hover {
      background-color: $color-red-3;
    }

    &:active {
      background-color: $color-red-4;
    }
  }

  &--link {
    padding: 0;
    height: auto;
    background: none;
    padding: 4px 0;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  &--primary,
  &--secondary,
  &--danger {
    &:focus-visible {
      box-shadow: 0px 0px 0px 5px $color-blue-4;
    }

    &:disabled {
      background-color: $color-gray-5;
      color: $color-gray-9;
      cursor: not-allowed;

      &:hover {
        color: darken($color-gray-9, 20%);
      }
    }
  }
}
