@import "./../../styles/colors.scss";

.cropper {
  .modal__content {
    width: 100%;

    .modal__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__crop {
    width: 100%;
    height: 400px;
  }

  &__actions {
    display: flex;
    padding: 5px;
    justify-content: flex-end;
    gap: 10px;
  }

  &__hints {
    margin-top: 0;
    margin-bottom: 30px;
  }

  &__preview {
    &-image-wrapper {
      width: 100%;
      height: 50vh;
      margin-bottom: 30px;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
