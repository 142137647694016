@import "../../kit/styles/colors.scss";

.header-navigation-more {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;

  &__body {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__text {
    text-decoration: none;
    font-family: ALS Ekibastuz;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: $color-blue-7;

    &:hover {
      color: darken($color-blue-7, 10%);
    }
  }

  &__icon {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    border-color: $color-blue-7;
    margin-top: -4px;

    &--active {
      transform: rotate(-135deg);
      margin-top: 0;
    }
  }

  &__menu {
    position: absolute;
    z-index: 2;
    background-color: $color-white;
    margin-top: 22px;
    border-radius: 8px;
    box-shadow: 0px 4px 32px rgba($color-black, 0.16);
    padding: 30px;
    left: -50%;
    min-width: 210px;

    &-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &-close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border: none;
      background-color: transparent;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 2px;
        width: 2px;
        height: 20px;
        background-color: $color-blue-7;
      }

      &::before {
        transform: rotate(-45deg);
      }
      &::after {
        transform: rotate(45deg);
      }
    }
  }
}
