.declarations-list-filter {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto [last-line];

  &__buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;

    > * {
      flex: 1;
    }
  }
}
