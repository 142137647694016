@import "./../../styles/colors.scss", "./../../styles/mixins.scss",
  "./../../styles/vars.scss";

.text-field {
  position: relative;
  display: flex;

  &__input {
    width: 100%;
    font-size: 17px;
    line-height: 18px;
    padding: 19px 18px;
    border: 1px solid $color-gray-4;

    &:focus {
      box-shadow: $bs-control-focus;
    }

    &::placeholder {
      opacity: 0;
    }
  }

  &__label {
    @include absoluteCenter($vertical: true);
    left: 19px;
    right: 0;
    pointer-events: none;
    overflow: hidden;
    transition: all 300ms ease;
    color: $color-gray-1;
    white-space: nowrap;
  }

  .text-field__error-button {
    display: none;
  }

  &--error {
    .text-field__input {
      border: 1px solid $color-red-5;
      padding-right: 44px;
    }

    .text-field__error-button {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      border: 2px solid $color-red-6;
      border-radius: 50%;
      margin-right: 20px;
      cursor: pointer;
      background: none;

      &:after {
        @include absoluteCenter;
        content: "";
        display: block;
        height: 12px;
        width: 2px;
        border-top: 7px solid $color-red-6;
        border-bottom: 2px solid $color-red-6;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }

  .text-field__input:not(:placeholder-shown) + .text-field__label,
  .text-field__input:autofill + .text-field__label,
  .text-field__input:focus + .text-field__label {
    top: 8px;
    font-size: 13px;
    line-height: 13px;
    color: $color-gray-9;
  }

  &__required-label {
    position: absolute;
    font-size: 13px;
    top: 100%;
    padding-top: 4px;
    padding-left: 20px;
  }
}
